import React, { useState, useEffect, createRef } from 'react';
import axios                                     from 'axios';
import { gsap }                                  from 'gsap/all';
import emailjs                                   from 'emailjs-com';
import { PageTitle }                             from '../PageTitle';
import { useTranslation }                        from 'react-i18next';
import ReCAPTCHA                                 from "react-google-recaptcha";
import ComingSoon                                from '../ComingSoon/ComingSoon';

import './Signup.css';
import { error } from 'jquery';
import { json } from 'body-parser';

gsap.registerPlugin();
emailjs.init("user_ylRkbq4cFtIZCHkk5c620")

const Signup = () => {
    const recaptchaRef = createRef();
    const { t, i18n } = useTranslation();
    const [response, setResponse] = useState(null);
    const [reCAPTCHA, setReCAPTCHA] = useState(false);
    const [visitorLocation, setVisitorLocation] = useState(null);
    const [data, setData] = useState({ title: '', email: ''});
    const [error, setError] = useState({ title: false, email: false });
    const [users, setUsers] = useState([]);

    useEffect(() => {
        
        gsap.to(".signup-container", {x:-0, opacity: 1, duration: 1, delay: 1 });

        // axios.get('https://www.cloudflare.com/cdn-cgi/trace')
        //     .then(response => setVisitorLocation(response.data))
        //     .catch(error => console.log("error ", error));

        // axios.get('http://localhost:3000/users')
        //     .then((res) => {
        //         return res.json();
        //     })
        //     .then((data) => {
        //         setUsers(data);
        //     })
        //     .catch( (err) => {
        //         console.log(err);
        //     });

    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        setData({ title: '', email: '' });
    };

    const onTyping = (type, value) => {

        setData({
            ...data,
            [type]: value
        });

        let errors = {
            ...error,
            [type]: value === ''
        };
        
        if (type === 'email')
            errors = {
                ...errors,
                email: !emailValidate(value)
            };

        setError(errors);
    };

    const emailValidate = input =>  {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const validEmail = re.test(input);

        return validEmail;
    };

    const isButtonDisabled = data.message === '' || data.title === '' || emailValidate(data.email) === false || !reCAPTCHA ;

    const arabicStyle = i18n.language === 'ar' ? {
        textAlign : 'right'
    }: {};

    return <ComingSoon className="signup-container" />
};


// <div className="signup-container p-3 p-lg-0" >
//     <PageTitle title="Signup"/>
//     <p className="mt-3" style={arabicStyle}>
//         {t('If you would like to communicate, you can send an email via this form')}
//     </p>
//     {response === null
//         ? <form className="mailing">
//             <div>
//                 <div className={`email-title ${i18n.language === 'ar' ? 'ar' : ''}`}>
//                     <input
//                         type="text"
//                         value={data.title}
//                         className={`${error.title ? 'error' : ''}`}
//                         onChange={e => onTyping("title", e.target.value)}
//                     />
//                     <span>{t('Email Title')}</span>
//                 </div>
//                 <div className={`visitor-email ${i18n.language === 'ar' ? 'ar' : ''}`}>
//                     <input
//                         type="text"
//                         value={data.email}
//                         className={`${error.email ? 'error' : ''}`}
//                         onChange={e => onTyping("email", e.target.value)}
//                     />
//                     <span>{t('Your Email address')}</span>
//                 </div>
            
//             </div>
            
//             <ReCAPTCHA
//                 ref={recaptchaRef}
//                 sitekey="6Lf0J8IZAAAAAB2jic7844dhpZrzyFurxU9HJwtI"
//                 onChange={ e => setReCAPTCHA(e)}
//             />

//             <button disabled={isButtonDisabled} className="mt-4 button" onClick={event => handleSubmit(event)}>{t('Send')}</button>

//         </form>
//         : <div className="mt-5" >
//             {response}
//         </div>
//     }
// </div >

export default Signup;

