import React, { useState, useEffect, useRef } from 'react';
import { useTranslation }                     from 'react-i18next';
import { RkLogoSharp }                        from '../Svg/SvgIcons';
import { Link, useLocation }                  from 'react-router-dom';
import styled                                 from 'styled-components';

import './Header.css'

const Header = ({ darkTheme, lightTheme, themeTogel, routerRef, nextPageController }) =>
{
    const node = useRef();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [IsMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const [ isDarkTheme, setTheme ] = useState(true);

    useEffect(() => {
        let currentTheme = localStorage.getItem('currentTheme');

        currentTheme === 'dark'
            ?   setTheme(true)
            :   setTheme(false);
    }, []);

    const themeToggel = isDark => {

        isDark === true
            ?   localStorage.setItem('currentTheme', 'dark')
            :   localStorage.setItem('currentTheme', 'light');


        const style = `:root { ${isDark ? darkTheme : lightTheme} }`;

        const head = document.getElementsByTagName('head')[0];
        const styleElement = document.createElement('style');
        styleElement.setAttribute('type','text/css');
        styleElement.appendChild(document.createTextNode(style));
        head.appendChild(styleElement);

        setTheme(isDark);
        themeTogel();
    };

    const mobileMenuController = isOpened => {
        if ( !isOpened ) {
            routerRef.current.style.maxHeight= "100vh";
            routerRef.current.style.overflow= "hidden";
        } else {
            routerRef.current.style.maxHeight= "unset";
            routerRef.current.style.overflow= "auto";
        }

        setIsMobileMenuOpen(!isOpened);
    };

    const tabs = [
        {url: "/main", name : "Main Page"},
        {url: "/experiences", name : "Experiences"},
        {url: "/skills", name : "Skills"},
        {url: "/about", name : "About"},
        {url: "/contact", name : "Contact"},
        {url: "/signup", name : "Signup"}
    ];

    const lang = i18n.language
    const styles = { 
        padding: lang !== 'ar' ? '8px 20px' : '5px 24px',
        letterSpacing : lang === 'ar' ? '0' : '2px',
        fontSize : lang !== 'ar' ? '14px' : '18px' 
    };


    return (
        <div className="header">
            
            <RkLogoSharp onClick={() => themeToggel(!isDarkTheme)} isDarkTheme={isDarkTheme} />

            {/*
                <RkLogoRound onClick={() => themeToggel(!isDarkTheme)} isDarkTheme={isDarkTheme} />
            */}
            
            <div className="menu">
                {tabs.map((tab, idx) => (
                    <a key={idx} onClick={() => nextPageController(tab.url, location.pathname)} >
                        <span
                            style={styles}
                            className={ location.pathname === tab.url ? `nav-button active nav-button-${idx}` : `nav-button nav-button-${idx}`}
                        >    
                            {t(`${tab.name}`)}
                        </span>
                    </a>
                ))}
            </div>

            <div className="mobile-menu" ref={node}>
                <BurgerButton
                    IsMobileMenuOpen={IsMobileMenuOpen}
                    mobileMenuController={mobileMenuController}
                />
                <Menu
                    t={t}
                    tabs={tabs}
                    lang={lang}
                    location={location}
                    IsMobileMenuOpen={IsMobileMenuOpen}
                    nextPageController={nextPageController}
                    mobileMenuController={mobileMenuController}
                />
            </div>
        </div>
    );
};

const BurgerButton = ({ IsMobileMenuOpen, mobileMenuController }) =>
(
    <StyledBurgerButton IsMobileMenuOpen={IsMobileMenuOpen} onClick={() => mobileMenuController(IsMobileMenuOpen)}>
        <div />
        <div />
        <div />
    </StyledBurgerButton>
);

const Menu = ({ IsMobileMenuOpen, tabs, t, lang, mobileMenuController, location, nextPageController }) =>
(
    <StyledMenu IsMobileMenuOpen={IsMobileMenuOpen} lang={lang}>
        {tabs.map((tab, idx) => (
            <a key={idx}
                onClick={() => {
                    nextPageController(tab.url, location.pathname);
                    mobileMenuController(IsMobileMenuOpen)
                }} >
                <span className={location.pathname === tab.url ? 'tab-active' : 'tab'} role="img" aria-label="contact" style={{ letterSpacing: `${lang === 'ar' ? '0' : '2px'}`}} >
                    {t(`${tab.name}`)}
                </span>
            </a>
        ))}
    </StyledMenu>
);

const StyledMenu = styled.nav`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--main-background-color);
    letter-spacing: ${({ lang }) => lang === 'ar' ? '0' : '2px'};
    transform: ${({ IsMobileMenuOpen }) => IsMobileMenuOpen ? 'translateX(0)' : 'translateX(-100%)'};
    height: 100vh;
    text-align: left;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    transition: transform 0.3s ease-in-out;

    @media (max-width: 576px) {
        width: 100%;
        }

    a {
        font-size: 2rem;
        text-transform: uppercase;
        padding: 2rem 0;
        font-weight: bold;
        letter-spacing: 0.5rem;
        color: var(--main-text-color);
        text-decoration: none;
        transition: color 0.3s linear;

        @media (max-width: 576px) {
        font-size: 1.5rem;
        text-align: center;
        }

        &:hover {
        color: #343078;
        }
    }
`

const StyledBurgerButton =styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent !important;
    border: none !important;
    cursor: pointer;
    padding: 0;
    z-index: 11;

    &:focus {
        outline: none;
    }

    div {
        width: 2rem;
        height: 3px;
        background: ${({ IsMobileMenuOpen }) => IsMobileMenuOpen ? 'var(--main-text-color)' : 'var(--main-text-color)'};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
        transform: ${({ IsMobileMenuOpen }) => IsMobileMenuOpen ? 'rotate(45deg)' : 'rotate(0)'};
        }

        :nth-child(2) {
        opacity: ${({ IsMobileMenuOpen }) => IsMobileMenuOpen ? '0' : '1'};
        transform: ${({ IsMobileMenuOpen }) => IsMobileMenuOpen ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
        transform: ${({ IsMobileMenuOpen }) => IsMobileMenuOpen ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`

export default Header;