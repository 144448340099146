import React              from 'react';
import { useTranslation } from 'react-i18next';

import './Footer.css'

const Footer = () => {

    const { i18n } = useTranslation();

    const LanguagesSwitcher = lang => {
        localStorage.setItem('i18nextLng', lang);
        i18n.changeLanguage(lang);

        // Change the font
        let style = '';

        if (lang === 'ar') {
            style = ':root { --current-font: "Tajawal", sans-serif; }';
        } else {
            style = ':root { --current-font: "Montserrat", sans-serif; }';
        };

        const head = document.getElementsByTagName('head')[0];
        const styleElement = document.createElement('style');
        styleElement.setAttribute('type', 'text/css');
        styleElement.appendChild(document.createTextNode(style));
        head.appendChild(styleElement);
    };

    const availablelanguages = ['de', 'en', 'ar'];
    const date = new Date().toJSON().slice(0, 4);

    const styles = (lang) => {
        let styles = {
            color: `${i18n.language === lang ? 'var(--main-strong-color)' : 'var(--main-text-color)'}`,
            fontFamily: `Montserrat, sans-serif`
        };
        return styles
    };

    return (
        <div className="footer-wrapper">
            <div className="footer">
                <div className="languages d-inline-block ">
                    <p>
                        {availablelanguages.map((lang, idx) => (
                            <span className="ml-2 mr-2" style={styles(lang)} key={idx} onClick={() => LanguagesSwitcher(lang)} >{lang}</span>
                        ))}
                    </p>
                </div>
                <div className="d-inline-block date ml-5">
                    © | {date}
                </div>
            </div>
        </div>
    );
}

export default Footer;