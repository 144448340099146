import React, { useEffect, useState } from 'react';
import { gsap }                       from 'gsap/all';
import { PageTitle }                  from '../PageTitle';
import { useTranslation }             from 'react-i18next';
import { useLocation }                from 'react-router-dom';
import ComingSoon                     from '../ComingSoon/ComingSoon';
import Video1                         from "../../assets/videos/How_UGC's_Admins_think_you_are_cheating_^_^.mp4";
import Video2                         from '../../assets/videos/1_VS_10_Gla---;-(---diator_Counter-Strike_1.6_http__ugc.lt_server.mp4';

import './About.css';

gsap.registerPlugin();

const About = ({ nextPageController }) =>
{
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const isArabicClass = i18n.language ? 'arabic' : '';
    const arabicStyle = i18n.language === 'ar' ? {textAlign : 'right'}: {};
    
    const [isComingSoon] = useState(true);

    useEffect(() => {
        gsap.to(".about-container", {x:0, opacity: 1, duration: 1, delay: 1 });
    }, []);

    if (isComingSoon)
    return <ComingSoon className="about-container"/>;

    return (
        <div className="about-container p-2 p-md-0" >
            {/* About */}
            <PageTitle title="About"/>
            <DescriptionText
                t={t}
                text="dummy-text"
                arabicStyle={arabicStyle}
                isArabicClass={isArabicClass}
            />

            <SubTitle
                t={t}
                title="Photography"
                arabicStyle={arabicStyle}
                className="subtitle"
                isArabicClass={isArabicClass}
                nextPageController={() => nextPageController("/about/photography", location.pathname )}
            />

            <SubTitle
                t={t}
                title="3D"
                arabicStyle={arabicStyle}
                className="subtitle"
                isArabicClass={isArabicClass}
                nextPageController={() => nextPageController("/about/3d", location.pathname )}
            />

            {/* Animation 
            <Block
                t={t}
                text="dummy-text"
                title="3D &amp; Animation"
                arabicStyle={arabicStyle}
                isArabicClass={isArabicClass}
            />

            {/* Gaming 
            <Block
                t={t}
                title="Gaming" 
                text="dummy-text"
                arabicStyle={arabicStyle}
                isArabicClass={isArabicClass}
            />

            <div className="video-container row m-0 mb-5">
                <div className="col-md-6 p-1">
                    <video controls className="video-stream html5-main-video mb-4" style={{width: "100%", left: "0px", top: "0px"}} src={Video1} />
                    <iframe controls={true} width="100%" height="350px" src="https://www.youtube.com/embed/mER68UAIMec" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className="col-md-6 p-1">
                    <video controls className="video-stream html5-main-video mb-4" style={{width: "100%", left: "0px", top: "0px"}} src={Video2} />   
                    <iframe controls={true} width="100%" height="350px" src="https://www.youtube.com/embed/QsFQFAe77FM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className="col-md-6 p-1">
                    <video controls className="video-stream html5-main-video mb-4" style={{width: "100%", left: "0px", top: "0px"}} src={Video2} />   
                    <iframe controls={true} width="100%" height="350px" src="https://www.youtube.com/embed/3TObkPg3UzE" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
            */}
        </div > 
    )
};

const Block = ({ title, text, t, isArabicClass, arabicStyle }) =>
(
    <React.Fragment>
        <SubTitle
            t={t}
            title={title}
            arabicStyle={arabicStyle}
            isArabicClass={isArabicClass}
            />
        <DescriptionText 
            t={t}
            text={text}
            arabicStyle={arabicStyle}
            isArabicClass={isArabicClass}
        />
    </React.Fragment>
);

const SubTitle = ({ title, t, isArabicClass, arabicStyle,className, nextPageController }) =>
(
    <h5 onClick={() => { nextPageController && nextPageController() }} style={arabicStyle} className={`mt-3 ${isArabicClass} ${className ? className : ""}`}>
        {t(title)}
    </h5>
);

const DescriptionText = ({ text, t, isArabicClass, arabicStyle }) =>
(
    <p style={arabicStyle} className={`mt-3 ${isArabicClass}`}>
        {t(text)}
    </p>
);

export default About;

