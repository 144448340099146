import React, { useRef, useEffect, useState } from 'react';
import * as THREE                   from 'three';
import { gsap }                     from 'gsap/all';
import { useHistory }               from 'react-router-dom';
import { BackIcon }                 from '../../../Svg/SvgIcons';
// import StarImage                    from '../../../../assets/img/star.png'
// import Image                        from './star.png'

import './stars.css';

gsap.registerPlugin();

const Stars = () => {
    let stars, star;
    const [showBackButton, SetShowBackButton ] = useState(false);
    const canvasRef = useRef();
    const history = useHistory();
    const scene = new THREE.Scene();
    const starGeo = new THREE.Geometry();
    const renderer = new THREE.WebGLRenderer();
    const camera = new THREE.PerspectiveCamera(
        60,
        window.innerWidth / window.innerHeight,
        1,
        2000
    );
        
    useEffect(() =>
    {
        gsap.from(".stars-container", {opacity: 0, duration: 2 });

        setTimeout(() => {
            init();
            window.addEventListener("resize", onWindowResize(), false);
        }, 2500);
        
    }, []);

    // On Unmount
    useEffect(() => () => {
        window.removeEventListener("resize", onWindowResize());
    }, []);

    const init = () => {
        camera.position.z = 1;
        camera.rotation.x = Math.PI/2;

        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(devicePixelRatio);
        
        canvasRef.current && canvasRef.current.appendChild(renderer.domElement);

        for(let i=0;i<6000;i++) {
            star = new THREE.Vector3(
                Math.random() * 600 - 300,
                Math.random() * 600 - 300,
                Math.random() * 600 - 300
            );
            if (i<5) {
                console.log("star ",star)
            }
            star.velocity = 0;
            star.acceleration = 0.02;
            starGeo.vertices.push(star);
        }

        // let sprite = new THREE.TextureLoader().load(Image);
        let starMaterial = new THREE.PointsMaterial({
            color: 0xaaaaaa,
            size: 0.3,
            // map: sprite
        });

        stars = new THREE.Points(starGeo,starMaterial);
        scene.add(stars);

        animate(); 
    }

    const onWindowResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
    };

    const animate = () => {
        starGeo.vertices.forEach(p => {

            p.velocity += p.acceleration
            p.y -= p.velocity;
            
            if (p.y < -200) {
                p.y = 200;
                p.velocity = 0;
            }
        });
        starGeo.verticesNeedUpdate = true;
        stars.rotation.y +=0.0012;
      
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
        SetShowBackButton(true);
    };

    const routerGoBack = () => {
        gsap.to(".stars-container", {
            opacity: 0,
            duration: 2,
            onComplete: () => {
                history.push("/about");
            }
        });
    };

    return (
        <div className="stars-container">
            <div ref={canvasRef}></div>
            {showBackButton && <BackIcon onClick={routerGoBack}/>}
        </div>
    )
};

export default Stars;