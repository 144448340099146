import React       from 'react';
import randomColor from 'randomcolor';
import TagCloud    from 'react-tag-cloud';

import './AnimatedText.css';


class AnimatedText extends React.Component {
    
    state = {
        styles : {
            large: {
                fontSize: 45,
                fontWeight: 'bold'
            },
            small: {
                opacity: 0.7,
                fontSize: 26
            }
        }
    };

    componentDidMount() {
        const newStyles = {
            ...this.state.styles,
            large: {
                fontSize: Math.random() > 0.50 ? Math.random() + 25 : Math.random() * 10 + 25,
            },
            small: {
                opacity: Math.random(),
                fontSize: Math.random() > 0.50 ? Math.random() * 20 + 16 : Math.random() + 16
            }
        }

        setInterval(() => {
            this.forceUpdate();
        }, 4000);
    };

    isRotate = state => {
        if (state > 0.5) {
            return 90
        };
        return 0;
    };

    whatStyle = state => {
        if (state > 0.5) {
            return {
                    fontSize:  this.fontSize(),
                    fontWeight: 'bold'
                }
        }
        return {
                opacity: 0.7,
                fontSize: this.fontSize()
            };
    };

    fontSize = () => {
        const random = Math.random();
        if (random > 0.5 ) {
            return 26
        }
        return 45 
    };

    render() {
        const text = [
            "Developer",
            "Engineer",
            "Ranny Kaddoura",
            "React JS",
            "React Native",
            "Javascripte",
            "Electrical Engineer",
            "Telecommunication",
            "15 years experience",
            "in IT field",
            "Front-end",
            "PHP",
            "SQL",
            "MangoDB",
            "GraphQl",
            "express js",
            "Team player",
            "Responsive",
            "Illustrator",
            "Database",
            "designe",
            "SASS",
            "SCSS",
            "ReactNative",
            "since",
            "ReactJS",
            "html",
            "Photoshop",
            "specializing",
            "energy",
            "worked",
            "ASP.NET",
            "MVC 5",
            "C#",
            "DOTNET",
            "Team work",
            " Developer",
            " Engineer",
            " Ranny Kaddoura",
            " React JS",
            " React Native",
            " Javascripte",
            " Electrical Engineer",
            " Telecommunication",
            " 15 years experience",
            " in IT field",
            " Front-end",
            " PHP",
            " SQL",
            " MangoDB",
            " GraphQl",
            " express js",
            " Team player",
            " SASS",
            " Responsive",
            " Illustrator",
            " Database",
            " designe",
            " SCSS",
            " ReactNative",
            " since",
            " ReactJS",
            " html",
            " Photoshop",
            " specializing",
            " energy",
            " worked",
            " ASP.NET",
            " MVC 5",
            " C#",
            " DOTNET",
            " Team work",
        ];    
        return (
            <div className='app-outer'>
                <div className='app-inner'>
                    <TagCloud 
                        className='tag-cloud'
                        style={{
                            fontFamily: 'sans-serif',
                            fontSize: (Math.random() * 10) + 20,
                            color: () => randomColor({ hue: 'orange' }),
                            padding: 5,
                            opacity: 0.1
                        }}>
                        <div style= {{ fontFamily: 'serif', fontStyle: 'italic', fontWeight: 'bold', color: randomColor({ hue: 'orange' }), fontSize: (Math.random() * 10) + 20, }} >Software</div>
                        {text.map( (itemText, index) => (
                            <div
                                key={index}
                                rotate={() => this.isRotate(Math.random())}
                                style={() => this.whatStyle(Math.random())}
                            >
                                {itemText}
                            </div>
                        ))}
                    </TagCloud>
                </div>                
            </div>
        );
    }
};

export default AnimatedText;

