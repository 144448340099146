import React , { useEffect }       from 'react';
import { gsap }                    from 'gsap/all';
import { useTranslation }          from 'react-i18next';
import { useLocation }             from 'react-router-dom';
import { CloseIcon }               from '../../Svg/SvgIcons';
import { PhotoGraphicComponent }   from './PhotoGraphicComponent';
import Background                  from '../../../assets/img/kaddoura-logo.png';

import './Photography.css';

gsap.registerPlugin();

const Photography = ({ nextPageController }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const isArabicClass = i18n.language ? 'arabic' : '';
    const arabicStyle = i18n.language === 'ar' ? {textAlign : 'right'}: {};

    useEffect(() => {
        gsap.timeline()
        .from(".photography-container",           {opacity: 0, duration: 1.5 })
        .from(".logo-background",                 {opacity: 0, duration: 1 })
        .from(".photography-secondary-container", {opacity: 0, duration: 2 })
        .from("#close-icon",                      {opacity: 0, duration: 1, delay: 5 });
    }, []);

    return (
        <div className="photography-container">
            <div>
                <img className='logo-background' src={Background} atl="background"/>
            </div>
            <div className='photography-secondary-container container'>
                <Block
                    t={t}
                    text="photography_intro_1" 
                    title="Photography"
                    arabicStyle={arabicStyle}
                    isArabicClass={isArabicClass}
                />
                <DescriptionText 
                    t={t}
                    text="photography_intro_2" 
                    arabicStyle={arabicStyle}
                    isArabicClass={isArabicClass}
                />

                <PhotoGraphicComponent />
            </div>
            <CloseIcon onClick={() => nextPageController("/about", location.pathname)} />
        </div>
    );
};

const Block = ({ title, text, t, isArabicClass, arabicStyle }) =>
(
    <React.Fragment>
        <SubTitle
            t={t}
            title={title}
            arabicStyle={arabicStyle}
            isArabicClass={isArabicClass}
            />
        <DescriptionText 
            t={t}
            text={text}
            arabicStyle={arabicStyle}
            isArabicClass={isArabicClass}
        />
    </React.Fragment>
);

const SubTitle = ({ title, t, isArabicClass, arabicStyle }) =>
(
    <h5 style={arabicStyle} className={`mt-3 ${isArabicClass}`}>
        {t(title)}
    </h5>
);

const DescriptionText = ({ text, t, isArabicClass, arabicStyle }) =>
(
    <p style={arabicStyle} className={`mt-3 ${isArabicClass}`}>
        {t(text)}
    </p>
);

export default Photography;