import React      from 'react'
import { Stars  } from './Stars';


const ThreeDScene = () => {
  return (
    <div>
        <Stars />
    </div>
  )
};

export default ThreeDScene;