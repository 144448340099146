import React, { useState }               from 'react';
import { gsap }                          from 'gsap/all';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Photos }                        from './Photos';
import { Dslr, Apeture, Iso, ExposureTime,
    FocalLength, Lens, Flash, Calendar } from '../../../Svg/SvgIcons';

import './PhotoGraphicComponent.css';

gsap.registerPlugin();

export default function PhotoGraphicComponent () {

    const [showModal, setShowModal ] = useState(false);
    const [modal, setModal] = useState(false);
    const [currentImage, setCurrentImage ] = useState({
        info:{
            date: "",
            device: "",
            lense: "",
            aperture:"",
            focalLength: "",
            exposureTime: "",
            iso: "",
            isFlash: false
        },
        link:""}
    );

    // useEffect(() => {
    //     gsap.to(".selected-image", {x:0, opacity: 1, duration: 1, delay: 1 });
    // }, []);

    
    const selectImage = imageObject => {
        setCurrentImage(imageObject);
        gsap.from(".selected-image", {opacity: 0, duration: 2, delay: 1 });
        setModal(!modal);
    };

    const toggleModal = () => setModal(!modal);

    return (
        <React.Fragment>
            <RotatImagesCategory
                photos={Photos}
                selectImage={selectImage}
            />
            
            <ModalComponent
                modal={modal}
                toggleModal={toggleModal}
                currentImage={currentImage}
            />
        </React.Fragment>
    );
};

const RotatImagesCategory = ({photos, selectImage}) =>
(
    <div className="text-center photos">
        <div className="images-box">
            {photos.map((imageObject,idx) => (
                <span key={idx} className={`span${idx+1}`}>
                    <img onClick={() => selectImage(imageObject)} src={imageObject.link}/>
                </span>
            ))}
        </div>
    </div>
);


const ModalComponent = ({modal, toggleModal, currentImage}) =>
{
    const fields = [
        {component: Calendar , key: currentImage.info.date },
        {component: Dslr , key: currentImage.info.device },
        {component: Lens , key: currentImage.info.lense },
        {component: Apeture , key: currentImage.info.aperture },
        {component: FocalLength , key: currentImage.info.focalLength },
        {component: ExposureTime , key: currentImage.info.exposureTime },
        {component: Iso , key: currentImage.info.iso },
        {component: Flash , key: currentImage.info.isFlash ? "Yes" : "No" },
    ];

    return (
        <Modal isOpen={modal} onClick={toggleModal} toggle={toggleModal} >
            <ModalBody>
                <img onClick={toggleModal} style={{ maxWidth: "1200px"}} className={`selected-image${modal ? '-show' : '-hide'}`} src={currentImage.link} />
            </ModalBody>
            <ModalFooter>
                <div className="row" style={{ width: "100%", margin:"auto", maxWidth:"1200px"}}>
                    <h4 className="col-12 mb-5 mt-4">Photo properties</h4>
                    {fields.map((Line, index) => (
                        <div key={index} className="col-12 col-md-6 mb-3 pl-5 pl-md-3">
                            <Line.component /> {Line.key}
                        </div> 
                    ))}
                </div>
            </ModalFooter>
        </Modal>
    )
};