import React, { useEffect, useState } from 'react';
import { gsap }                       from 'gsap/all';
import { PageTitle }                  from '../PageTitle';
import { useTranslation }             from 'react-i18next';
import ComingSoon                     from '../ComingSoon/ComingSoon';

import './Experiences.css';

gsap.registerPlugin();

const Experiences = () =>
{
    const { t, i18n } = useTranslation();
    const [isComingSoon , setIsComingSoon] = useState(true);

    useEffect(() => {
        gsap.to(".experiences-container", {x:0, opacity: 1, duration: 1, delay: 1 });
    }, []);

    const arabicStyle = i18n.language === 'ar' ? {
        textAlign : 'right'
    }: {};

    return (
        isComingSoon
            ? <ComingSoon className="experiences-container"/>
            : <div className="experiences-container pr-3 pl-3 pr-lg-0 pl-lg-0" >        
                <PageTitle title="Experiences"/>
                <p style={arabicStyle}>
                    {t('dummy-text')}
                </p>
            </div >
    );
};

export default Experiences;