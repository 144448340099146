import React, { useEffect, useState } from 'react';
import { gsap }                       from 'gsap/all';
import { useTranslation }             from 'react-i18next';
import ReactTooltip                   from "react-tooltip";
import Swoosh                         from '../../assets/Sounds/swoosh_effect.mp3';
import SilverBadge                    from '../../assets/img/badge_silver.png';
import BronzBadge                     from '../../assets/img/badge_bronze.png';
import GoldBadge                      from '../../assets/img/badge_gold.png';
import { ReactLogo, JsLogo, PsLogo, AiLogo, SqlLogo, PhpLogo, Css3Logo, VrayLogo, SassLogo, JestLogo, A3DsLogo, ACadLogo,
        MySqlLogo, Html5Logo, ReduxLogo, JQueryLogo, PythonLogo, MangoDBLogo, DotNetLogo, GraphQlLogo, BootstrapLogo, } from '../Svg/SvgIcons';

import './Skills.css';

gsap.registerPlugin();

const Skills = ({ isDarkTheme }) =>
{    
    const { t, i18n } = useTranslation();
    const [isBlackIcone, setisBlackIcone] = useState(isDarkTheme);

    useEffect(() => {

        gsap.timeline()
            .to(".skills-container", {opacity: 1, duration: 1 })
        // category one title
            .from(".category-Coding", {opacity: 0,  duration: 0.4})
            // lineOne
            .from("#jquery", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#sass", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#js", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#php", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            // lineTwo
            .from("#group-lineTwo", {height: 0, duration: 0.2})
            .from("#html5", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#css3", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#jest_", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#redux", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            // lineThree
            .from("#group-lineThree", {height: 0, duration: 0.2})
            .from("#react", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#bootstrap", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#python", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#dotnet", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
        // category Two title
            .from("#category-DB", {height: 0, duration: 0.2})
            .from(".category-DB", {opacity: 0,  duration: 0.4})
            // lineFour
            .from("#group-lineFour", {height: 0, duration: 0.2})
            .from("#sql", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#mysql", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#gql", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#mangodb", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
        // category Three title
            .from(".category-Design", {height: 0, duration: 0.4})
            .from(".category-Design", {opacity: 0, duration: 0.4})
            // lineFive
            .from("#group-lineFive", {height: 0, duration: 0.2})
            .from("#ps", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#ai", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#a3ds", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            .from("#aCad", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})
            // lineSix
            .from("#group-lineSix", {height: 0, duration: 0.2})
            .from("#vray", {x: -100, rotateX: 90 ,opacity: 0, duration: 0.5})

            .from(".icon-color", { height: 0, opacity: 0, duration: 0.5})

    }, []);

    const icons = { 
        coding : {
            lineOne : [
                {ComponentName: JQueryLogo, badge:[GoldBadge,GoldBadge], className: 'jquery' , text:"JQuery"},
                {ComponentName: SassLogo, badge:[GoldBadge,GoldBadge], className: 'sass' , text:"Sass/Scss"},
                {ComponentName: JsLogo, badge:[GoldBadge, GoldBadge,GoldBadge], className: 'js' , text:"JavaScript"},
                {ComponentName: PhpLogo, badge:[BronzBadge, BronzBadge,SilverBadge], className: 'php' , text:"Php" },
            ],
            lineTwo : [
                {ComponentName: Html5Logo, badge:[GoldBadge,GoldBadge,GoldBadge], className: 'html5' , text:"Html:5" },
                {ComponentName: Css3Logo, badge:[GoldBadge,GoldBadge, GoldBadge], className: 'css3' , text:"CSS3" },
                {ComponentName: JestLogo, badge:[GoldBadge,GoldBadge], className: 'jest_', text:"Jest, React testing library'" },
                {ComponentName: ReduxLogo, badge:[GoldBadge,GoldBadge,GoldBadge], className: 'redux' , text:"Redux"}
            ],
            lineThree : [
                {ComponentName: ReactLogo, badge:[GoldBadge,GoldBadge,GoldBadge], className: 'react' , text:"ReactJS / React native" },
                {ComponentName: BootstrapLogo, badge:[GoldBadge,GoldBadge, GoldBadge], className: 'bootstrap' , text:"Bootstrap"} ,
                {ComponentName: PythonLogo, badge:[BronzBadge,BronzBadge], className: 'python' , text:"Python" },
                {ComponentName: DotNetLogo, badge:[SilverBadge, SilverBadge,SilverBadge], className: 'dotnet' , text:"DotNet" },
            ],
        },
        db : {
            lineFour : [
                {ComponentName: SqlLogo, badge:[SilverBadge,SilverBadge,SilverBadge], className: 'sql' , text:"Sql" },
                {ComponentName: MySqlLogo, badge:[SilverBadge,SilverBadge,SilverBadge], className: 'mysql' , text:"MySql" },
                {ComponentName: GraphQlLogo, badge:[GoldBadge], className: 'gql', text:"GraphQL"},
                {ComponentName: MangoDBLogo, badge:[GoldBadge], className: 'mangodb', text:"MangoDB"}
            ]
        },
        design : {
            lineFive : [
                {ComponentName: PsLogo, badge:[GoldBadge,GoldBadge,GoldBadge], className: 'ps' , text:"Photoshop" },
                {ComponentName: AiLogo, badge:[GoldBadge,GoldBadge,GoldBadge], className: 'ai' , text:"Illustrator" },
                {ComponentName: A3DsLogo, badge:[GoldBadge,GoldBadge,GoldBadge], className: 'a3ds' , text:"3ds MAX" },
                {ComponentName: ACadLogo, badge:[GoldBadge,GoldBadge], className: 'aCad' , text:"AutoCad" }
            ],
            lineSix : [
                {ComponentName: VrayLogo, badge:[GoldBadge,GoldBadge], className: 'vray', text:"VRay" },
            ]
        }
    };

    const arabicStyle = i18n.language === 'ar' ? {
        textAlign : 'right'
    }: {};

    return (
        <div className="skills-container" style={{ opacity: "0" }}>
            <div className={i18n.language !== 'ar' ? 'text-right' : ''}>
                <button className="icon-color mr-2" onClick={() => setisBlackIcone(!isBlackIcone)} >
                    {isBlackIcone ? t("Original Icons") : t("B & W Icons")}
                </button>
            </div>
            <CategoryTitle title="Design" t={t} arabicStyle={arabicStyle} />

            <Group
                line="lineSix"
                Swoosh={Swoosh}
                isDarkTheme={isBlackIcone}
                category={icons.design}
            />
            <Group
                line="lineFive"
                Swoosh={Swoosh}
                category={icons.design}
                isDarkTheme={isBlackIcone}
            />

            <CategoryTitle title="DB" t={t} arabicStyle={arabicStyle} />
            <Group
                line="lineFour"
                Swoosh={Swoosh}
                category={icons.db}
                isDarkTheme={isBlackIcone}
            />


            <CategoryTitle title="Coding" t={t} arabicStyle={arabicStyle} />

            <Group
                Swoosh={Swoosh}
                line="lineThree"
                category={icons.coding}
                isDarkTheme={isBlackIcone}
                tooltipItems={[

                ]}
            />
            <Group
                line="lineTwo"
                Swoosh={Swoosh}
                category={icons.coding}
                isDarkTheme={isBlackIcone}
            />
            <Group
                line="lineOne"
                Swoosh={Swoosh}
                category={icons.coding}
                isDarkTheme={isBlackIcone}
            />
        </div>
    );
};

const Group = ({ category, isDarkTheme, line }) =>
(
    <div id={`group-${line}`} className={`m-0 mt-2 row stretch-in group-${line}`}>        
        {category[line].map((Item, idx)  => (
            <div key={idx} id={Item.className} className="col-3" data-for={Item.text} data-tip={Item.text}>
                <Tooltip id={Item.text} text={Item.text} /> 
                <nav>
                    {Item.badge.map((badge,i) => (
                        <img key={i} className={`skill-badge b${i}`} src={badge}/>
                    ))}   
                    <Item.ComponentName className={Item.className} isDarkTheme={isDarkTheme}/>
                </nav>
            </div>
        ))}
    </div>
);

const Tooltip = ({ text, id, children }) =>
(
    <ReactTooltip effect='solid' id={id} >
        {text && <p>{text}</p>}
        {children && children}        
    </ReactTooltip>
    
);

const CategoryTitle = ({ title, t, arabicStyle }) =>
(
    <div id={`category-${title}`} className={`mt-2 col-12 title category-${title}`} style={arabicStyle}>
        {t(title)}
    </div>
);

export default Skills;
