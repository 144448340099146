import React, { useEffect } from 'react';
import { gsap }             from 'gsap/all';
import { PageTitle }        from '../PageTitle';
import { useTranslation }   from 'react-i18next';
import AnimatedText         from './AnimatedText/AnimatedText';
import profileImage         from '../../assets/img/rannyKaddoura.jpg';

import './MainPage.css';
gsap.registerPlugin();

const Mainpage = () =>
{
    const { t, i18n } = useTranslation();  
    const arabic = i18n.language === 'ar';

    useEffect(() => {
        gsap.timeline()        
            .to(".main-page", {x:0, opacity: 1, duration: 1, delay:0.2 })
            .from(".app-outer", {opacity:0, duration:0.5 })
            .from(".mainpage-container", {x: arabic ? 100 : -100, opacity: 0, duration: 1 })
            .from(".profileImage", {borderRadius: 0, opacity: 0, x: arabic ? 100 : -100, duration: 1 });
        }, []);

    const arabicStyle = arabic ? {
        textAlign : 'right'
    }: {};

    return (
        <div className="main-page">

            <AnimatedText />

            {arabic
                ?   <React.Fragment><Photo profileImage={profileImage} /><Text t={t} arabicStyle={arabicStyle} /></React.Fragment>
                :   <React.Fragment><Text t={t} arabicStyle={arabicStyle} /><Photo profileImage={profileImage} /></React.Fragment>  
            }

        </div>
    );
};

const Text = ({ arabicStyle, t }) =>
(
    <div className="col-lg-6 mainpage-container pr-3 pl-3 pr-xl-0 pl-xl-0" >               
        <PageTitle title="Main Page"/>
        <p className="mt-3" style={arabicStyle}>
            {t('dummy-text')}
            {t('dummy-text')}
            {t('dummy-text')}
        </p>
    </div >
);

const Photo = ({ profileImage }) =>
(
    <div className="text-center col-lg-6 p-5">
        <img src={profileImage} alt="Ranny Kaddoura photo" className="profileImage"/>
    </div>
);

export default Mainpage;