import React              from 'react';
import { useTranslation } from 'react-i18next';

import './Introduction.css'

const Introduction = ({ onAnimationEnd }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className='introduction' onAnimationEnd={onAnimationEnd}>
            <span className={`firstText ${i18n.language}`}>{t('Welcome to')}</span>
            <span className='secondText'>{t('Ranny Kaddoura personal webpage')}</span>
        </div>
    )};

export default Introduction;