import React              from 'react';
import { ComingSoonLogo } from '../Svg/SvgIcons';

import './ComingSoon.css';

export default function ComingSoon({className}) {
    return (
        <div className="p-5 text-center coming-soon">
            <ComingSoonLogo className={className}/>
        </div>
    )
}
