import React, { useState, useEffect, createRef } from 'react';
import axios                          from 'axios';
import { gsap }                       from 'gsap/all';
import emailjs                        from 'emailjs-com';
import { PageTitle }                  from '../PageTitle';
import { useTranslation }             from 'react-i18next';
import ReCAPTCHA                      from "react-google-recaptcha";

import './Contact.css';

gsap.registerPlugin();
emailjs.init("user_ylRkbq4cFtIZCHkk5c620")

const Contact = () => {
    const recaptchaRef = createRef();
    const { t, i18n } = useTranslation();

    const [response, setResponse] = useState(null);
    const [reCAPTCHA, setReCAPTCHA] = useState(false);
    const [visitorLocation, setVisitorLocation] = useState(null);
    const [data, setData] = useState({ title: '', email: '', message: '' });
    const [error, setError] = useState({ title: false, email: false, message: false });

    useEffect(() => {
        gsap.to(".contact-container", {x:0, opacity: 1, duration: 1, delay: 1 });

        axios.get('https://www.cloudflare.com/cdn-cgi/trace')
            .then(response => setVisitorLocation(response.data))
            .catch(error => console.log("error ", error));
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        let tempMessage = data;
        setData({ title: '', email: '', message: '' });

        const validForm = data.message !== '' && data.title !== '' && data.email !== ''

        if (validForm) {
            const templateId = 'template_7B03E2Li';
            const templateParams = {
                title: data.title,
                message_html: data.message,
                from_sender: data.email,
                location: visitorLocation
            };
            emailjs.send('default_service', templateId, templateParams)
                .then(res => {
                    setResponse(res.status === 200 ? <div style={{ color: '#43dd43' }}>{t('Your email has been sent successfully!')}</div> : response);
                    setData(tempMessage);
                })
                .catch(err => {
                    setResponse(err.status !== 200 ? <div style={{ color: 'red' }}>{t('Oh well, sending failed. Here some thoughts on the error that occurred')} : {err.text} </div> : response);
                    setData(tempMessage);
                })
        } else {
            console.log("elseeeeeee validForm ")
        };
    };

    const onTyping = (type, value) => {

        setData({
            ...data,
            [type]: value
        });

        let errors = {
            ...error,
            [type]: value === ''
        };
        
        if (type === 'email')
            errors = {
                ...errors,
                email: !emailValidate(value)
            };

        setError(errors);
    };

    const emailValidate = input =>  {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const validEmail = re.test(input);

        return validEmail;
    };

    const isButtonDisabled = data.message === '' || data.title === '' || emailValidate(data.email) === false || !reCAPTCHA ;
    const isArabic = i18n.language === 'ar' ? 'ar' : '';
    const arabicStyle = isArabic ? {
        textAlign : 'right'
    } : {};

    return (
        <div className="contact-container pr-3 pl-3 pr-lg-0 pl-lg-0" >            
            <PageTitle title="Contact"/>
            <p className="mt-3" style={arabicStyle}>
                {t('If you would like to communicate, you can send an email via this form')}
            </p>
            {response === null
                ? <form className="mailing">
                    <div>
                        <div className={`email-title ${isArabic}`}>
                            <input
                                type="text"
                                value={data.title}
                                className={`${error.title ? 'error' : ''}`}
                                onChange={e => onTyping("title", e.target.value)}
                            />
                            <span>{t('Email Title')}</span>
                        </div>
                        <div className={`visitor-email ${isArabic}`}>
                            <input
                                type="text"
                                value={data.email}
                                className={`${error.email ? 'error' : ''}`}
                                onChange={e => onTyping("email", e.target.value)}
                            />
                            <span>{t('Your Email address')}</span>
                        </div>
                        <h4 style={arabicStyle}>{t('Content')}</h4>
                    
                        <textarea
                            required
                            id="mailing"
                            name="mailing"
                            value={data.message}
                            className={`${error.message ? 'error' : ''} ${isArabic}`}
                            onChange={e => onTyping("message", e.target.value)}
                            style={{ width: '100%', height: '150px', padding: '10px' }}
                        />
                    </div>
                    
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lf0J8IZAAAAAB2jic7844dhpZrzyFurxU9HJwtI"
                        onChange={ e => setReCAPTCHA(e)}
                    />

                    <button disabled={isButtonDisabled} className="mt-4 button" onClick={event => handleSubmit(event)}>{t('Send')}</button>

                </form>
                : <div className="mt-5">
                    {response}
                </div>
            }
        </div >
    );
};

export default Contact;