
import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./Form.css";

/**
 * @component Form
 * @props - { object } -  config
 */
const Form = (props) => {
    const [mailSent, setmailSent] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({});

    /**
    * @function handleFormSubmit
    * @param e { obj } - form event
    * @return void
    */
    const handleFormSubmit = e => {
        e.preventDefault();
        axios({
            method: "post",
            url: `/cgi-sys/FormMail.cgi`,
            headers: { "content-type": "application/json" },
            data: formData
        })
            .then(result => {
                console.log(`result `, result )
                if (result.data.sent) {
                    console.log(`result.data `, result.data );
                    console.log(`result.data.sent `, result.data.sent );
                    setmailSent(result.data.sent)
                    setError(false)
                } else {
                    setError(true)
                }
            })
            .catch(error => {
                console.log(`error `, error );
                setError(error.message);
            });
    };
    /**
      * @function handleChange
      * @param e { obj } - change event
      * @param field { string } - namve of the field
      * @return void
      */
    const handleChange = (e, field) => {
        let value = e.target.value;
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const { title, description, successMessage, errorMessage, fieldsConfig } = props.config;
    return (
        <div className="contact-form">
            <div className="contact-form__header">
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
            <div className="contact-form__container">
                <div>
                    <form action="/cgi-sys/FormMail.cgi" method="post">
                        {fieldsConfig &&
                            fieldsConfig.map(field => {
                                return (
                                    <React.Fragment key={field.id}>
                                        {field.type !== "textarea" ? (
                                            <div className="row offset-1">
                                                <div className="col-2">
                                                    <label>{field.label}</label>
                                                </div>
                                                <input
                                                    type={field.type}
                                                    className={`col-8 mb-2 ${field.class}`}
                                                    placeholder={field.placeholder}
                                                    value={field.name}
                                                    onChange={e => handleChange(e, field.fieldName)}
                                                    />
                                            </div>
                                        ) : (
                                            <div className="row offset-1" style={{display: "flex", alignItems: "start"}}>
                                                <div className="col-2">
                                                    <label>{field.label}</label>
                                                </div>
                                                <textarea className={`col-8 mb-2 ${field.class}`} placeholder={field.placeholder} onChange={e => handleChange(e, field.fieldName)} value={field.name} />
                                            </div>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            <div className="row offset-1">
                                <div className="col-11 text-right">
                                    <input type="submit" onClick={e => handleFormSubmit(e)} value="Submit" />
                                </div>
                            </div>
                        <div>
                            {mailSent && <div className="sucsess">{successMessage}</div>}
                            {error && <div className="error">{errorMessage}</div>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

//propTypes for the component
Form.propTypes = {
    config: PropTypes.object.isRequired
};

export default Form;