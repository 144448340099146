export const Photos = [
  { info:{
      device: "Canon EOS 7D Mark II",
      lense: "EF-S15-85mm f/3.5-5.6 IS USM",
      date: "July 15, 2016",
      aperture:"f/8.0",
      focalLength: "15.0 mm",
      exposureTime: "30",
      iso: "100",
      isFlash: false
  }, link:"https://live.staticflickr.com/7794/27716972753_353b28252b_o.jpg"},
  { info:{
      device: "Canon EOS 7D Mark II",
      lense: "EF-S15-85mm f/3.5-5.6 IS USM",
      date: "July 15, 2016",
      aperture:"f/11.0",
      focalLength: "15.0 mm",
      exposureTime: "20",
      iso: "100",
      isFlash: false
  }, link:"https://live.staticflickr.com/7297/27716569494_c61eb77d91_o.jpg"},
  { info:{
      date: "December 20, 2015",
      device: "Canon EOS 7D Mark II",
      lense: "EF-S55-250mm f/4-5.6 IS II",
      aperture:"f/5.6",
      focalLength: "179.0 mm",
      exposureTime: "1/25",
      iso: "16000",
      isFlash: false
  }, link:"https://live.staticflickr.com/733/23580010150_8c5d7922f1_o.jpg"},
  { info:{
      date: "December 9, 2015",
      device: "Canon EOS 7D Mark II",
      lense: "EF-S55-250mm f/4-5.6 IS II",
      aperture:"f/4.5",
      focalLength: "55.0 mm",
      exposureTime: "1/80",
      iso: "160",
      isFlash: false
  }, link:"https://live.staticflickr.com/5769/23548769011_7079463f55_o.jpg"},
  { info:{
      date: "December 7, 2015",
      device: "Canon EOS 7D Mark II",
      lense: "EF50mm f/1.8 STM",
      aperture:"f/1.8",
      focalLength: "50.0 mm",
      exposureTime: "1/1000",
      iso: "16000",
      isFlash: false
  }, link:"https://live.staticflickr.com/5815/23296165690_9f0b2b7ff3_o.jpg"},
  { info:{
      date: "September 3, 2014",
      device: "Canon EOS 5D Mark II",
      lense: "unregistered",
      aperture:"f/22.0",
      focalLength: "24.0 mm",
      exposureTime: "3.2",
      iso: "100",
      isFlash: false
  }, link:"https://live.staticflickr.com/5664/23488062966_b25b163ab4_o.jpg"},
  { info:{
      date: "December 2, 2015",
      device: "Canon EOS 7D Mark II",
      lense: "unregistered",
      aperture:"f/4.5",
      focalLength: "84.0 mm",
      exposureTime: "1/2500",
      iso: "200",
      isFlash: false
  }, link:"https://live.staticflickr.com/620/23178334390_73c2bb7412_o.jpg"},
  { info:{
      date: "December 2, 2015",
      device: "Canon EOS 7D Mark II",
      lense: "unregistered",
      aperture:"f/4.0",
      focalLength: "55.0 mm",
      exposureTime: "1/8000",
      iso: "500",
      isFlash: false
  }, link:"https://live.staticflickr.com/5753/22845792934_d9eca902cd_o.jpg"},
];
