import i18n                 from 'i18next';
import LanguageDetector     from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: {
                    "About": "About me",
                    "Main Page" : "Main Page",
                    "Education" : "Education",
                    "Skills" : "Skills",
                    "Contact" : "Contact",
                    "Welcome to" : "Welcome to",
                    "Ranny Kaddoura" : "Ranny Kaddoura",
                    "Ranny Kaddoura personal webpage" : "Ranny Kaddoura personal webpage",
                    "UNDER CONSTRUCTIONS" : "UNDER CONSTRUCTIONS",
                    "SKIP" : "SKIP",
                    "Subject" : "Subject :",
                    "Your EMail" : "Your EMail",
                    "write your message" : "Write your message ...",
                    "the site will be Launched in" : "the site will be Launched in",
                    "dummy-text" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                    "If you would like to communicate, you can send an email via this form" : "If you would like to communicate, you can send an email via this form",
                    "Email Title" : "Email Title",
                    "Your Email address" : "Your Email address",
                    "Send" : "Send",
                    "Content" : "Content",
                    "Your email has been sent successfully!" : "Your email has been sent successfully!",
                    "Oh well, sending failed. Here some thoughts on the error that occurred" : "Oh well, sending failed. Here some thoughts on the error that occurred",
                    "Photographic design" : "Photographic design",
                    "Coding" : "Coding",
                    "Design" : "Design",
                    "Signup" : "Signup",
                    "Experiences" : "Experiences",
                    "Original Icons" : "Original Icons",
                    "about_me_text" : "My name is Ranny Kaddoura, I studied electrical engineering  telecommunications major and then renewable energies, but my passion was owned by informatics and everything related to it. I am a software engineer working since the beginning of 2018 in a well-known and prestigious Swiss company, I love 3D design, drawing and photography.",
                    "photography_intro_1" : "I get used to taking my camera and my equipments in each day that I felt it is enough coding today.",
                    "photography_intro_2" : "Honestly, some days I felt that I want to go to the next level and start doing a professional photography, but I couldn't stay long far from me PC :P"
                }
            },
            de: {
                translations: {
                    "About": "Über mich",
                    "Main Page" : "Hauptseite",
                    "Education" : "Bildung",
                    "Skills" : "Kompetenzen",
                    "Contact" : "Kontakt",
                    "Welcome to" : "Willkommen zur",
                    "Ranny Kaddoura" : "Ranny Kaddoura",
                    "Ranny Kaddoura personal webpage" : "Ranny Kaddoura persönliche Webseite",
                    "UNDER CONSTRUCTIONS" : "UNTER KONSTRUKTIONEN",
                    "SKIP" : "ÜBERSPRINGEN",
                    "Subject" : "Gegenstand :",
                    "Your EMail" : "Deine E-Mail",
                    "write your message" : "Schreiben Ihre Nachricht ...",
                    "the site will be Launched in" : "Die Site wird gestartet im",
                    "dummy-text" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                    "If you would like to communicate, you can send an email via this form" : "Wenn Sie kommunizieren möchten, können Sie über dieses Formular eine E-Mail senden",
                    "Email Title" : "E-Mail-Titel.",
                    "Your Email address" : "Ihre Emailadresse.",
                    "Send" : "Senden",
                    "Content" : "Inhalt",
                    "Your email has been sent successfully!" : "Ihre E-Mail wurde erfolgreich gesendet!",
                    "Oh well, sending failed. Here some thoughts on the error that occurred" : "Na ja, das Senden ist fehlgeschlagen. Hier einige Gedanken zu dem aufgetretenen Fehler",
                    "Photographic design" : "Fotografisches Design",
                    "Coding" : "Codierung",
                    "Design" : "Design",
                    "Signup" : "Anmeldung",
                    "Experiences" : "Erfahrungen",
                    "Original Icons" : "Ursprüngliche Symbole",
                    "about_me_text" : "Mein Name ist Ranny Kaddoura, ich habe Elektrotechnik-Hauptfach Telekommunikation und dann erneuerbare Energien studiert, aber meine Leidenschaft gehörte der Informatik und allem, was damit zu tun hat. Ich bin Softwareentwickler und arbeite seit Anfang 2018 in einem bekannten und renommierten Schweizer Unternehmen. Ich liebe 3D-Design, Zeichnen und Fotografieren.",
                    "photography_intro_1" : "Ich gewöhne mich daran, meine Kamera und meine Ausrüstung an jedem Tag mitzunehmen, an dem ich das Gefühl hatte, dass es heute genug Codierung ist.",
                    "photography_intro_2" : "Ehrlich gesagt hatte ich an manchen Tagen das Gefühl, dass ich auf die nächste Stufe gehen und mit der professionellen Fotografie beginnen möchte, aber ich konnte mich nicht lange von meinem PC fernhalten :P"
                }
            },
            ar: {
                translations: {
                    "About": "نبذة",
                    "Main Page" : "الصفحة الرئيسية",
                    "Education" : "الخلفية التعليمية",
                    "Skills" : "المهارات",
                    "Contact" : "الاتصال",
                    "Welcome to" : "أهلا وسهلا بك في",
                    "Ranny Kaddoura" : "راني قدورة",
                    "Ranny Kaddoura personal webpage" : "الصفحة الشخصية لـ راني قدورة",
                    "UNDER CONSTRUCTIONS" : "تحت الصيانة",
                    "SKIP" : "تخطى",
                    "Subject" : ": الموضوع",
                    "Your EMail" : ": بريدك الالكتروني",
                    "write your message" : " .... اكتب رسالتك",
                    "the site will be Launched in" : "سيتم إطلاق الموقع في",
                    "dummy-text" : "عندما يريد العالم أن ‪يتكلّم ‬ ، فهو يتحدّث بلغة يونيكود. تسجّل الآن لحضور المؤتمر الدولي العاشر ليونيكود (Unicode Conference)، الذي سيعقد في 10-12 آذار 1997 بمدينة مَايِنْتْس، ألمانيا. و سيجمع المؤتمر بين خبراء من كافة قطاعات الصناعة على الشبكة العالمية انترنيت ويونيكود، حيث ستتم، على الصعيدين الدولي والمحلي على حد سواء مناقشة سبل استخدام يونكود في النظم القائمة وفيما يخص التطبيقات الحاسوبية، الخطوط، تصميم النصوص والحوسبة متعددة اللغات..",
                    "If you would like to communicate, you can send an email via this form" : "إذا كنت ترغب في التواصل ، يمكنك إرسال بريد إلكتروني عبر هذا النموذج",
                    "Email Title" : "الموضوع",
                    "Your Email address" : "عنوان بريدك الإلكتروني",
                    "Send" : "إرسال",
                    "Content" : "المحتوى",
                    "Your email has been sent successfully!" : "تم إرسال البريد الإلكتروني الخاص بك بنجاح!",
                    "Oh well, sending failed. Here some thoughts on the error that occurred" : "حسنًا ، فشل الإرسال. هنا بعض الأفكار عن الخطأ الذي حدث",
                    "Photographic design" : "التصميم الفوتوغرافي",
                    "Coding" : "البرمجة",
                    "Design" : "التصميم",
                    "Signup" : "التسجيل",
                    "Experiences" : "الخبرات",
                    "Original Icons" : "الأيقونات الأصلية",
                    "about_me_text" : "أنا راني قدورة , درست الهندسة الكهربائية اختصاص هندسة الاتصالات ثم الطاقات المتجددة, ولكن شغفي كان ملكا للمعلوماتية وكل ما يتعلق بها. أنا الأن مهندس برمجيات أعمل منذ بداية عام 2018 في احدى الشركات السويسرية المعروفة والمرموقة, أحب التصميم الثلاثي الأبعاد والرسم والتصوير الفوتوغرافي.",
                    "photography_intro_1" : "اعتدت على أخذ الكاميرا ومعداتي في كل يوم شعرت أنه يكفي اليوم.",
                    "photography_intro_2" : "بصراحة ، شعرت في بعض الأيام أنني أريد الانتقال إلى المستوى التالي والبدء في التصوير الفوتوغرافي الاحترافي ، لكنني لم أستطع البقاء بعيدًا عني جهاز الكمبيوتر: P"
                }
            },
        },
        fallbackLng: "en",

        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;