import React, { useState, useEffect, useRef }  from 'react';
import { gsap }                                from 'gsap/all';
import { Helmet }                              from 'react-helmet';
import { useTranslation }                      from 'react-i18next';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { About }                               from '../Components/About';
import { Skills }                              from '../Components/Skills';
import { Footer }                              from '../Components/Footer';
import { Header }                              from '../Components/Header';
import { Contact }                             from '../Components/Contact';
import { Signup }                              from '../Components/Signup';
import { MainPage }                            from '../Components/MainPage';
import { ThreeDScene }                         from '../Components/About/3D';
import { Experiences }                         from '../Components/Experiences';
import { Introduction }                        from '../Components/Introduction';
import { themes }                              from '../Components/Themes/Themes';
import { Photography }                         from '../Components/About/Photography';

import { Form } from '../Components/TestPHPEmail';

gsap.registerPlugin();

const Router = () => {
    let history = useHistory();
    const routerRef = useRef();
    const { t, i18n } = useTranslation();

    const [initialTheme, setInitialTheme] = useState(true);
    const [introductionLoadin, setIntroductionLoadin] = useState(true);

    /**
     * Set the language token
     */
    useEffect(() => {
        const lang = localStorage.getItem('i18nextLng');
        const currentTheme = localStorage.getItem('currentTheme');
        const arabicFont = '--current-font: "Tajawal", sans-serif;'; 

        let styleQuery = ''

        currentTheme === null
            ? localStorage.setItem('currentTheme', 'dark')
            : currentTheme === 'light'
                ? styleQuery = themes.lightTheme
                : styleQuery = themes.darkTheme;

        currentTheme === 'light' && setInitialTheme(false);

        let style = `:root {${styleQuery}}`;

        /**
         * Set Language Token if it is not exist
         */
        if (lang === null) {
            localStorage.setItem('i18nextLng', 'de');
            i18n.changeLanguage('de')
        };

        if (lang !== 'en' || lang !== 'ar' || lang !== 'de') {
            i18n.changeLanguage('de');
        };

        /**
         * Set the last selected language by the user in i18n instance
         */
        if (lang === 'en' || lang === 'ar' || lang === 'de') {
            i18n.changeLanguage(lang);
        };

        if (lang === 'ar') {
            style = `:root { ${arabicFont} ${styleQuery}}`;
        };

        const head = document.getElementsByTagName('head')[0];
        const styleElement = document.createElement('style');
        styleElement.setAttribute('type', 'text/css');
        styleElement.appendChild(document.createTextNode(style));
        head.appendChild(styleElement);

    }, []);

    /**
     * Animated the current page before call the router and show the next page
     */
    const nextPageController = (nextPage, currentLocation) => {

        if (nextPage !== currentLocation) {

            let removedPage;
            switch(currentLocation) {
                case "/":
                      removedPage = 'main-page';
                    break;
                case "/main":
                    removedPage = 'main-page';
                    break;
                case "/experiences":
                    removedPage = 'experiences-container';
                    break;
                case "/skills":
                    removedPage = 'skills-container';
                    break;
                case "/about":
                    removedPage = 'about-container';
                    break;
                case "/about/photography":
                    removedPage = 'photography-container';
                    break;
                case "/about/gaming":
                    removedPage = 'gaming-container';
                    break;
                case "/contact":
                    removedPage = 'contact-container';
                    break;
                case "/signup":
                    removedPage = 'signup-container';
                    break;
                default:
                    removedPage = '';
            };
    
            if (removedPage === "main-page") {
                gsap.timeline()
                    .to(".profileImage", {borderRadius: 0, opacity: 0, x: -100, duration: 1})
                    .to(".mainpage-container", {x: -100, opacity: 0, duration: 1 })
                    .to(".app-outer", { opacity:0, duration: 1, onComplete: afterAnimationEnded })
            } 
            else if (removedPage === "photography-container") {
                gsap.timeline()
                    .to(".header",                          {opacity: 0})
                    .to(".photography-secondary-container", {opacity: 0, duration: 1, delay: 0 })
                    .to(".logo-background",                 {opacity: 0, duration: 1, delay: 0 })
                    .to(".photography-container",           {opacity: 0, duration: 1, delay: 0, onComplete: afterAnimationEnded })
                    .to(".header",                          {opacity: 1, duration: 1, delay: 0 })
            }
            else  gsap.to(`.${removedPage}`, {x: -400, opacity: 0, duration: 1, onComplete: afterAnimationEnded });
    
            function afterAnimationEnded () {
                history.push(`${nextPage}`);
            };
        };
    };

    const config = {
        api : '',
        title : "Contact Me",
        description : "Drop us a message ",
        successMessage : "Thank you for contact me.",
        errorMessage : "Sorry we have some problems.",
        fields: {
            firstName : '',
            lastName : '',
            email : '',
            msg : '',
        },
        fieldsConfig : [
            {id :1, label: 'First Name', fieldName: "First Name", type: 'text', placeholder: 'Your First Name', isRequired: true, class: 'first-name-field'},
            {id :2, label: 'Last Name', fieldName: "Last Name", type: 'text', placeholder: 'Your Last Name', isRequired: true, class: 'last-name-field'},
            {id :3, label: 'Email', fieldName: "Email", type: 'email', placeholder: 'Your email .. ', isRequired: true, class: 'email-field'},
            {id :4, label: 'Message', fieldName: "Message", type: 'textarea', placeholder: 'Write something ... ', isRequired: true, class: 'message-field'},
        ]
    };

    return (
        <div ref={routerRef}>
            <Helmet>
                <title>Ranny Kaddoura | portfolio</title>
                <meta name="google-site-verification" content="AggkeUeIsByfhWaSxWn0I_brH57xkOv-WUd9oLQet6Q" />
                <meta name="keywords" content="Kaddoura,Ranny,Ranny Kaddoura,Kaddoura Ranny,Developer,Software developer, Software Engineer" />
                <meta name="description" content="Ranny Kaddoura, Telecommunication engineer and Software developer, this is a personal page that shows some information about me" />

                <meta name="robots" content="index, follow" />
                <meta name="bingbot" content="index, follow" />
                <meta name="googlebot" content="index, follow" />

                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0" />

                <meta property="og:locale" content="de_ch" />
                <meta property="og:type" content="Portfolio website" />
                <meta property="og:site_name" content="Ranny Kaddoura" />
                <meta property="og:url" content="https://kaddoura.ch/" />
                <meta property="og:title" content="telecommunication engineer and developer." />
                <meta property="og:description" content="Ranny Kaddoura, Telecommunication engineer and Software developer, this is a personal page that shows some information about me" />

            </Helmet>
            {introductionLoadin
                ? <React.Fragment>
                    <Introduction onAnimationEnd={() => setIntroductionLoadin(false)} />
                    <button className="button skip" onClick={() => setIntroductionLoadin(false)}>
                        {t('SKIP')}
                    </button>
                </React.Fragment>

                : <React.Fragment>
                    <Header
                        routerRef={routerRef}
                        darkTheme={themes.darkTheme}
                        lightTheme={themes.lightTheme}
                        nextPageController ={nextPageController}
                        themeTogel={() => setInitialTheme(!initialTheme)}
                    />
                    <div className="body" style={{ flex: 10, position: 'relative', minHeight: 'calc(100vh - 170px)'}}>
                        <Switch>
                            <Route path="/" exact component={() => <MainPage />} />
                            <Route path="/about/3d" exact component={() => <ThreeDScene nextPageController={nextPageController} />} />
                            <Route path="/about/photography" exact component={() => <Photography nextPageController={nextPageController} />} />
                            <Route path="/about" exact component={() => <About nextPageController={nextPageController} />} />
                            <Route path="/contact" exact component={() => <Contact />} />
                            <Route path="/main" exact component={() => <MainPage />} />
                            <Route path="/signup" exact component={() => <Signup />} />
                            <Route path="/skills" exact component={() => <Skills isDarkTheme={initialTheme} />} />
                            <Route path="/experiences" exact component={() => <Experiences />} />
                            <Route path="/form" exact component={() => <Form config={config} />} />
                            <Redirect from="*" to="/" exact/>
                        </Switch>
                    </div>
                    <Footer />
                </React.Fragment>
            }
        </div>
    );
};

const TemporaryPage = ({ t, i18n }) =>
(
    <div style={{ height: '100vh', width: '100vw', maxWidth: '1140px', margin: 'auto'}} className="row Container text-center">
        <div className={`mb-5 col-12 pt-5 ${i18n.language !== 'ar' ? 'text-left' : 'text-right'}`}>    
            <h1 className="title" style={{ fontSize: "20px"}}>
                {t('Ranny Kaddoura')}
            </h1>
        </div>
        
        <div className="col-12" style={{ minHeight: '200px'}} title="mt-5 m-5 construction Icons has been made by Freepik http://www.freepik.com/ from https://www.flaticon.com/">
            {t('UNDER CONSTRUCTIONS')}
            <svg
                className="mr-2 ml-2"
                id="Capa_1"
                enableBackground="new 0 0 512 512"
                height="30"
                width="30"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g>
                    <path d="m445.399 237.56c36.081-36.081 46.683-87.984 31.824-133.372-.619-1.891-3.02-2.466-4.427-1.059l-61.545 61.545c-1.273 1.273-3.129 1.771-4.869 1.305l-55.943-14.99c-1.739-.466-3.098-1.825-3.564-3.564l-14.99-55.943c-.466-1.739.031-3.596 1.305-4.869l61.545-61.545c1.407-1.407.832-3.808-1.059-4.427-45.389-14.859-97.292-4.256-133.372 31.824-32.385 32.385-44.246 77.517-35.59 119.244 4.089 19.715-2.471 40.122-17.557 53.457l-185.654 164.097c-27.257 23.43-28.829 65.095-3.413 90.511 25.416 25.416 67.081 23.845 90.51-3.413l164.098-185.653c13.335-15.086 33.741-21.647 53.457-17.557 41.727 8.654 86.859-3.206 119.244-35.591z"
                        fill="#e7ecf6"/>
                    <path d="m472.796 103.128-8.975 8.975c3.976 37.908-8.556 77.218-37.608 106.27-32.385 32.385-77.517 44.246-119.244 35.59-19.715-4.089-40.122 2.471-53.457 17.557l-164.098 185.654c-20.386 23.717-54.574 27.976-79.855 11.943 2.393 3.774 5.229 7.354 8.531 10.656 25.416 25.416 67.081 23.845 90.51-3.413 0 0 164.098-185.653 164.098-185.653 13.335-15.086 33.741-21.647 53.457-17.557 41.727 8.655 86.859-3.205 119.244-35.59 36.081-36.081 46.683-87.984 31.824-133.373-.618-1.891-3.02-2.466-4.427-1.059z"
                        fill="#d8e2f1"/>
                <g>
                <g>
                    <path d="m270.84 171.542c-1.978 0-3.955-.755-5.464-2.264-3.017-3.017-3.017-7.909.001-10.927l8.491-8.49c3.017-3.017 7.909-3.016 10.927.001 3.017 3.017 3.017 7.909-.001 10.927l-8.491 8.49c-1.509 1.509-3.486 2.263-5.463 2.263z"
                        fill="#d8e2f1"/>
                </g>
                <g>
                    <path d="m291.909 192.612c-1.977 0-3.955-.755-5.463-2.263-3.018-3.017-3.018-7.909-.001-10.927l8.49-8.491c3.018-3.018 7.91-3.017 10.927-.001 3.018 3.017 3.018 7.909.001 10.927l-8.49 8.491c-1.509 1.509-3.487 2.264-5.464 2.264z"
                        fill="#d8e2f1"/>
                </g>
                <g>
                    <path d="m312.979 213.681c-1.977 0-3.955-.755-5.463-2.263-3.018-3.017-3.018-7.909-.001-10.927l8.49-8.491c3.018-3.018 7.91-3.017 10.927-.001 3.018 3.017 3.018 7.909.001 10.927l-8.49 8.491c-1.51 1.509-3.488 2.264-5.464 2.264z"
                        fill="#d8e2f1"/>
                </g>
                <g>
                    <path d="m334.047 234.75c-1.977 0-3.955-.755-5.464-2.263-3.017-3.018-3.017-7.909 0-10.928l8.491-8.491c3.018-3.016 7.909-3.016 10.928 0 3.017 3.018 3.017 7.909 0 10.928l-8.491 8.491c-1.509 1.509-3.487 2.263-5.464 2.263z"
                        fill="#d8e2f1"/>
                </g>
                </g>
                    <g>
                        <path d="m47.261 458.328c-1.978 0-3.955-.755-5.464-2.264-3.017-3.017-3.017-7.909.001-10.927l14.256-14.255c3.017-3.017 7.909-3.016 10.927.001 3.017 3.017 3.017 7.909-.001 10.927l-14.256 14.255c-1.508 1.509-3.486 2.263-5.463 2.263z"
                            fill="#d8e2f1"/>
                    </g>
                    <path d="m507.481 317.298-46.159-26.65c-2.796-1.614-6.242-1.614-9.038 0l-46.16 26.65c-2.796 1.614-4.519 4.598-4.519 7.827v53.301c0 3.229 1.723 6.213 4.519 7.827l46.16 26.65c2.796 1.614 6.242 1.614 9.038 0l46.159-26.65c2.796-1.614 4.519-4.598 4.519-7.827v-53.301c0-3.229-1.723-6.213-4.519-7.827zm-50.679 55.27c-11.483 0-20.793-9.309-20.793-20.793 0-11.483 9.309-20.792 20.793-20.792 11.483 0 20.792 9.309 20.792 20.792.001 11.483-9.308 20.793-20.792 20.793z"
                        fill="#365e7d"/>
                    <path d="m347.739 379.636-46.159-26.65c-2.796-1.614-6.242-1.614-9.038 0l-46.16 26.65c-2.796 1.614-4.519 4.598-4.519 7.827v53.301c0 3.229 1.723 6.213 4.519 7.827l46.16 26.65c2.796 1.614 6.242 1.614 9.038 0l46.159-26.65c2.796-1.614 4.519-4.598 4.519-7.827v-53.301c0-3.229-1.722-6.212-4.519-7.827zm-50.678 55.27c-11.483 0-20.793-9.309-20.793-20.793 0-11.483 9.309-20.792 20.793-20.792s20.792 9.309 20.792 20.792c0 11.484-9.309 20.793-20.792 20.793z"
                        fill="#365e7d"/>
                    <g fill="#2b4d66">
                        <path d="m507.481 317.298-46.159-26.65c-2.797-1.614-6.242-1.614-9.038 0l-8.142 4.701 38.017 21.95c2.796 1.614 4.519 4.598 4.519 7.827v53.301c0 3.229-1.723 6.213-4.519 7.827l-38.017 21.949 8.142 4.701c2.796 1.614 6.241 1.614 9.038 0l46.159-26.65c2.796-1.614 4.519-4.598 4.519-7.827v-53.301c0-3.23-1.723-6.214-4.519-7.828z"/>
                        <path d="m347.739 379.636-46.159-26.65c-2.796-1.614-6.242-1.614-9.038 0l-8.142 4.701 38.017 21.949c2.797 1.614 4.519 4.598 4.519 7.827v53.301c0 3.229-1.722 6.213-4.519 7.827l-38.017 21.949 8.142 4.701c2.796 1.614 6.242 1.614 9.038 0l46.159-26.65c2.797-1.614 4.519-4.598 4.519-7.827v-53.301c0-3.229-1.722-6.212-4.519-7.827z"/>
                    </g>
                </g>
            </svg>
        </div>
        <div className="col-12" style={{ alignSelf: "flex-end" }}>
            <Footer />
        </div>
    </div>
);

export default Router;